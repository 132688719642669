import React, { useRef, useImperativeHandle, forwardRef, ForwardedRef, Fragment } from "react";

import { Property } from "csstype";

import { AttachmentsFormFile } from "@/attachments/components/attachments-form-file/AttachmentsFormFile";
import { AttachmentsFormImage } from "@/attachments/components/attachments-form-image/AttachmentsFormImage";
import { AttachmentsFormVideo } from "@/attachments/components/attachments-form-video/AttachmentsFormVideo";

import { IMAGE_FORMATS, VIDEO_FORMATS, FILE_FORMATS } from "@/attachments/constants";
import { AttachmentTypes, UploadsStatus } from "@/attachments/models";
import { ImageDataFragment, VideoDataFragment, FileDataFragment } from "@/graphql/types";

import useStyles from "./AttachmentsForm.styles";

type AttachmentsFormProps = {
  images: ImageDataFragment[];
  files?: FileDataFragment[];
  video?: VideoDataFragment | null;
  uploadsStatus: UploadsStatus;
  thumbnailSize?: "small" | "medium" | "large";
  adaptiveContainerSize?: Property.Width<string | number>;
  uploadFiles: (files: File[], type?: AttachmentTypes) => void;
  deleteImage: (data: string) => void;
  deleteFile?: (data: string) => void;
  deleteVideo: () => void;
};

export type AttachmentsFormRefProps = {
  trigger: (type: AttachmentTypes) => void;
};

export const AttachmentsForm = forwardRef<AttachmentsFormRefProps, AttachmentsFormProps>(
  (
    {
      images,
      files,
      video,
      uploadsStatus,
      thumbnailSize = "large",
      adaptiveContainerSize,
      uploadFiles,
      deleteImage,
      deleteFile,
      deleteVideo,
    }: AttachmentsFormProps,
    ref: ForwardedRef<AttachmentsFormRefProps>,
  ) => {
    const { classes, cx } = useStyles();
    const imagesUploadInputRef = useRef<HTMLInputElement>(null);
    const filesUploadInputRef = useRef<HTMLInputElement>(null);
    const videoUploadInputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      trigger: (type: AttachmentTypes) => {
        switch (type) {
          case AttachmentTypes.Image:
            imagesUploadInputRef.current?.click();
            break;
          case AttachmentTypes.Video:
            videoUploadInputRef.current?.click();
            break;
          default:
            filesUploadInputRef.current?.click();
            break;
        }
      },
    }));

    const handleImagesUpload = (files: FileList | null) => {
      files && uploadFiles(Array.from(files), AttachmentTypes.Image);
      if (imagesUploadInputRef.current) imagesUploadInputRef.current.value = "";
    };
    const handleFilesUpload = (files: FileList | null) => {
      files && uploadFiles(Array.from(files), AttachmentTypes.File);
      if (filesUploadInputRef.current) filesUploadInputRef.current.value = "";
    };
    const handleVideoUpload = (file: File | null) => {
      file && uploadFiles([file], AttachmentTypes.Video);
      if (videoUploadInputRef.current) videoUploadInputRef.current.value = "";
    };

    return (
      <>
        {(images.length || files?.length || video) && (
          <div
            className={cx(classes.grid, classes[thumbnailSize], {
              [classes.adaptiveSize]: !!adaptiveContainerSize,
            })}>
            {images.map((image) => (
              <Fragment key={`upload-file-image-${image.id}`}>
                <AttachmentsFormImage
                  isUploading={uploadsStatus[image.id]?.uploading}
                  progress={uploadsStatus[image.id]?.progress}
                  image={image}
                  thumbnailSize={thumbnailSize}
                  onDelete={() => deleteImage(image.id)}
                  adaptiveContainerSize={adaptiveContainerSize}
                />
              </Fragment>
            ))}
            {files?.map((file) => (
              <div key={`upload-file-${file.id}`}>
                <AttachmentsFormFile
                  isUploading={uploadsStatus[file.id]?.uploading}
                  progress={uploadsStatus[file.id]?.progress}
                  file={file}
                  thumbnailSize={thumbnailSize}
                  onDelete={() => deleteFile && deleteFile(file.id)}
                />
              </div>
            ))}
            {video && (
              <AttachmentsFormVideo
                isUploading={uploadsStatus[video.id]?.uploading}
                progress={uploadsStatus[video.id]?.progress}
                video={video}
                thumbnailSize={thumbnailSize}
                onDelete={() => deleteVideo()}
              />
            )}
          </div>
        )}
        <input
          hidden
          accept={IMAGE_FORMATS}
          onChange={(event) => handleImagesUpload(event.target.files)}
          ref={imagesUploadInputRef}
          type="file"
          multiple={true}
          data-cy="AttachmentsForm__images"
        />
        <input
          hidden
          accept={FILE_FORMATS}
          onChange={(event) => handleFilesUpload(event.target.files)}
          ref={filesUploadInputRef}
          type="file"
          multiple={true}
          data-cy="AttachmentsForm__files"
        />
        <input
          hidden
          accept={VIDEO_FORMATS}
          onChange={(event) => handleVideoUpload(event.target.files && event.target.files[0])}
          ref={videoUploadInputRef}
          type="file"
          multiple={false}
          data-cy="AttachmentsForm__video"
        />
      </>
    );
  },
);

AttachmentsForm.displayName = "AttachmentsForm";

import { ReactNode } from "react";

import { FeatureFlagName } from "@/feature-flags/constants/flags";
import { useFlag } from "@/feature-flags/contexts/FlagsContext";

export const FeatureFlagOnly = ({
  children,
  flag,
  forceEnabled,
}: {
  children: ReactNode;
  flag: FeatureFlagName;
  forceEnabled?: boolean;
}) => {
  const { enabled } = useFlag(flag);

  return enabled || forceEnabled ? <>{children}</> : null;
};

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  grid: {
    paddingTop: theme.spacing(1.25),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
  small: {},
  medium: {},
  large: {},
  adaptiveSize: {
    flexWrap: "nowrap",
    overflow: "auto",
    paddingBottom: theme.spacing(1),
  },
}));

export default useStyles;

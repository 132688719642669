import React, { SVGProps } from "react";

const PlusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M12 3c.932 0 1.688.756 1.688 1.688l-.001 5.624h5.626a1.688 1.688 0 0 1 0 3.376l-5.626-.001v5.626a1.688 1.688 0 0 1-3.374 0l-.001-5.626H4.688a1.688 1.688 0 0 1 0-3.374l5.624-.001V4.688C10.313 3.756 11.069 3 12 3z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default PlusIcon;

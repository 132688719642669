import React from "react";

function SendIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="currentColor" cx="20" cy="20" r="20" />
        <path d="M11 10h20v20H11z" />
        <path
          d="M13.498 26.667a.784.784 0 0 1 .142-.459l3.801-5.752a.834.834 0 0 0 0-.917l-3.801-5.752a.834.834 0 0 1 1.067-1.2l13.338 6.669a.834.834 0 0 1 0 1.484l-13.338 6.668a.834.834 0 0 1-1.209-.741z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SendIcon;

import { useEffect, useRef } from "react";

import useStyles from "./PdfPreview.styles";

type PdfPreviewProps = {
  file: string;
  width?: string;
  height?: string;
};

export const PdfPreview = ({ file, width = "100%", height = "300px" }: PdfPreviewProps) => {
  const { classes } = useStyles({ width, height });
  const iframe = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    // Try to reload IFrame if it doesn't load the first time
    if (iframe.current?.contentDocument && typeof iframe.current.contentDocument === "object") {
      setTimeout(() => {
        if (iframe.current?.contentDocument) {
          if (iframe.current.src.indexOf("timestamp") > -1) {
            iframe.current.src = iframe.current.src.replace(
              /timestamp=[^&]+/,
              "timestamp=" + Date.now(),
            );
          } else {
            iframe.current.src +=
              (iframe.current.src.indexOf("?") > -1 ? "&" : "?") + "timestamp=" + Date.now();
          }
        }
      }, 1000);
    }
  }, []);

  return (
    <div className={classes.previewContainer}>
      <iframe
        ref={iframe}
        src={`https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(file)}`}
        className={classes.previewIframe}></iframe>
    </div>
  );
};

import React, { ComponentType, useState } from "react";

import { SwipeableDrawerProps, Theme, useMediaQuery } from "@mui/material";

import {
  UserListDialog,
  UserListDialogProps,
} from "@/common/components/UserListDialog/UserListDialog";
import { UserListDialogUsers } from "@/common/components/UserListDialog/UserListDialogUsers/UserListDialogUsers";
import { UserListDrawer } from "@/common/components/UserListDrawer/UserListDrawer";

import { UserListEntity } from "@/common/models/UserListEntity";
import { UserBaseFragment, UserFragment } from "@/graphql/types";

export type UserListModalProps = {
  users: Array<UserBaseFragment | UserFragment>;
  loadingUsers?: boolean;
  hasMoreUsers?: boolean;
  multiselect?: boolean;
  UserAction?: ComponentType<{ [key: string]: unknown } & { user: UserListEntity }>;
  userActionProps?: { [key: string]: unknown };
  fetchMoreUsers?: () => void;
  onChange?: (ids: string[]) => void;
  type?: "dialog" | "drawer";
} & Omit<UserListDialogProps, "children"> &
  Omit<SwipeableDrawerProps, "onChange" | "onOpen">;

export const UserListModal = ({
  users,
  loadingUsers = false,
  hasMoreUsers = false,
  multiselect,
  UserAction,
  userActionProps = {},
  fetchMoreUsers,
  onChange,
  open,
  onClose,
  title,
  type,
  ...props
}: UserListModalProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const handleScroll = (event: MouseEvent) => {
    setIsScrolled(!!(event.target as HTMLDivElement).scrollTop);
  };

  return isMobile && type !== "dialog" ? (
    <UserListDrawer open={open} title={title} onClose={onClose} {...props}>
      <UserListDialogUsers
        users={users as UserListEntity[]}
        hasMoreUsers={hasMoreUsers}
        fetchMoreUsers={fetchMoreUsers}
        UserAction={UserAction}
        userActionProps={userActionProps}
        onScroll={() => {}}
      />
    </UserListDrawer>
  ) : (
    <UserListDialog open={open} title={title} onClose={onClose} isScrolled={isScrolled} {...props}>
      <UserListDialogUsers
        users={users as UserListEntity[]}
        loading={loadingUsers}
        hasMoreUsers={hasMoreUsers}
        multiselect={multiselect}
        fetchMoreUsers={fetchMoreUsers}
        onScroll={handleScroll}
        onChange={onChange}
        UserAction={UserAction}
        userActionProps={userActionProps}
      />
    </UserListDialog>
  );
};

import React from "react";

type ArrowIcon = { [key: string]: unknown } & { direction?: "top" | "right" | "bottom" | "left" };

function ArrowIcon(props: ArrowIcon) {
  const { direction = "right" } = props;
  const rotationMap: { [key: string]: number } = {
    right: 0,
    bottom: 90,
    left: 180,
    top: 270,
  };
  const style = { transform: `rotate(${rotationMap[direction]}deg)` };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      style={style}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M5 13h11.86l-3.63 4.36a1.001 1.001 0 1 0 1.54 1.28l5-6a1.19 1.19 0 0 0 .09-.15c0-.05.05-.08.07-.13A1 1 0 0 0 20 12a1 1 0 0 0-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 0 0-.09-.15l-5-6a1 1 0 0 0-1.41-.13 1 1 0 0 0-.13 1.41L16.86 11H5a1 1 0 0 0 0 2z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default ArrowIcon;

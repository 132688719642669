import { UploadsStatus } from "./UploadsStatus.model";

export type { UploadsStatus };

export enum AttachmentTypes {
  BlogArticleImage,
  Image,
  Video,
  File,
}

import * as UpChunk from "@mux/upchunk";
import { Observable } from "rxjs";

/**
 * Services based on mux documentation.
 *
 * See https://docs.mux.com/guides/video/upload-files-directly
 */
export const uploadVideoToMux = ({ file, uploadUrl }: { file: File; uploadUrl: string }) => {
  const upload = UpChunk.createUpload({
    // getUploadUrl is a function that resolves with the upload URL generated
    // on the server-side
    endpoint: uploadUrl,
    // picker here is a file picker HTML element
    file,
    chunkSize: 5120, // Uploads the file in ~5mb chunks
  });

  return new Observable<number>((observer) => {
    upload.on("progress", (event) => observer.next(Math.floor(event.detail)));

    upload.on("success", () => {
      observer.complete();
    });

    upload.on("error", (err) => {
      observer.error(err.detail);
    });
  });
};

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  preview: {
    position: "relative",
    "&:hover": {
      cursor: "pointer",
    },
  },
  poster: {
    width: "100%",
    maxHeight: "70vh",
    height: "100%",
    objectFit: "cover",
    filter: "brightness(75%)",
  },
  emptyPoster: {
    width: "100%",
    maxHeight: "70vh",
    minHeight: "9rem",
    objectFit: "contain",
    backgroundColor: theme.palette.grey[200],
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeButton: {
    background: theme.mode.icon.backgroundSecondary,
    fill: theme.mode.icon.default,
    color: theme.mode.icon.default,
    padding: "8px 8px 4px 8px",
    borderRadius: "32px",
    cursor: "pointer",
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
  },
  playerDialog: {
    borderRadius: 0,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#353348",
  },
  videoElement: {
    width: "100%",
  },
}));

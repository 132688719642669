import { BLOG_ARTICLE_SHORT_LENGTH } from "@/blogs/constants";

export const findMentionsInContent = (postContent: string): string[] => {
  const doc = document.createElement("html");
  doc.innerHTML = postContent;

  const mentions: string[] = [];

  for (const el of doc.querySelectorAll(".mention")) {
    // @ts-ignore
    if (el.dataset.id && !mentions.includes(el.dataset.id)) mentions.push(el.dataset.id);
  }

  return mentions;
};

export const isEmtpyArticle = (articleContent: string) => {
  const el = document.createElement("html");
  el.innerHTML = articleContent;
  return !articleContent || el.innerText?.trim().replace("\n", "").length === 0;
};

export const getHtmlContentLength = (html: string) => {
  if (typeof document !== "undefined") {
    const el = document.createElement("html");
    el.innerHTML = html;
    return el.innerText?.trim().length || 0;
  }
  return 0;
};

/*
  This function will parse the article HTML,
  convert into a NodeList array and we will
  return an HTML string with just a few of those
  NodeLists so that the shortened version of the
  article will not end in the middle of a sentence.
  At least that's the theory, there are some
  edge cases with more complicated HTML where
  it is prone to not work 100% as expected.
*/
export const stringToHtml = (str: string) => {
  if (typeof DOMParser !== "undefined") {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    const nodes = doc.body.childNodes;
    let shortdesc = "";
    let descriptionLength = 0;
    for (let i = 0; i < nodes.length; i++) {
      if (shortdesc.length < BLOG_ARTICLE_SHORT_LENGTH) {
        if (descriptionLength < BLOG_ARTICLE_SHORT_LENGTH) {
          shortdesc += nodes[i].firstChild?.parentElement?.outerHTML;
          if (!nodes[i].firstChild?.parentElement?.innerHTML.startsWith("<img src=")) {
            descriptionLength += nodes[i].firstChild?.parentElement?.outerHTML.length || 0;
          }
        } else {
          break;
        }
      }
      return shortdesc;
    }
    return "";
  }
};

export const findLinksInContent = (postContent: string): string[] => {
  const doc = document.createElement("html");
  doc.innerHTML = postContent;

  const links: string[] = [];

  for (const el of doc.querySelectorAll("a")) {
    if (el.href && !links.includes(el.href)) links.push(el.href);
  }

  return links;
};

import { useState, useEffect } from "react";

import { Box, SwipeableDrawer, SwipeableDrawerProps, Typography } from "@mui/material";

import useStyles from "./UserListDrawer.styles";

export type UserListDrawerProps = Omit<SwipeableDrawerProps, "onOpen"> & {
  children: React.ReactNode;
  title: string;
};

export const UserListDrawer = ({ open, onClose, children, title }: UserListDrawerProps) => {
  const { classes } = useStyles();

  const [container, setContainer] = useState<HTMLElement>();

  useEffect(() => {
    setContainer(window.document.body);
  }, []);

  return (
    <SwipeableDrawer
      container={container}
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      swipeAreaWidth={65}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: false,
      }}
      className={classes.userListDrawer}
      classes={{ paper: classes.userListDrawerPaper }}>
      <Box className={classes.userListDrawerHeader}>
        <Box className={classes.puller} />
        <Typography className={classes.userListTitle}>{title}</Typography>
      </Box>
      <Box className={classes.userListDrawerContent}>{children}</Box>
    </SwipeableDrawer>
  );
};

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { lighten } from "@/common/services";

// @ts-ignore
const useStyles = makeStyles()((theme: Theme) => ({
  mainContainer: {
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: 8,
    "& .imageOverride img": {
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  imageTile: {
    borderRadius: "0",
  },
  hiddenImage: {
    opacity: "100%",
  },
  hiddenTile: {
    ...theme.typography.h4,
    fontWeight: "bold",
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: lighten("#000000", 0.5),
  },
  singleImage: {
    width: "100%",
    maxHeight: "70vh",
    height: "100%",
    borderRadius: "0",
    objectFit: "contain !important",
    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.mode.text.title,
      maxHeight: "60vw",
      borderRadius: 0,
    },
  },
}));

export default useStyles;

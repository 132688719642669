import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";

import { Avatar, LinearProgress, Typography } from "@mui/material";

import { Property } from "csstype";
import { useTranslation } from "react-i18next";

import { PdfPreview } from "@/attachments/components/pdf-preview/PdfPreview";
import type { ConfirmDialogProps } from "@/common/components/ConfirmDialog/ConfirmDialog";

import CloseIcon from "@/common/icons/CloseIcon";

import { useConfirmDialog } from "@/common/hooks/use-confirm-dialog";

import { ImageDataFragment } from "@/graphql/types";

import useStyles from "./AttachmentsFormImage.styles";

const ConfirmDialog = dynamic<ConfirmDialogProps>(
  () =>
    import("@/common/components/ConfirmDialog/ConfirmDialog").then(
      (module) => module.ConfirmDialog,
    ),
  {
    ssr: false,
  },
);

type AttachmentsFormImageProps = {
  isUploading: boolean;
  progress: number;
  image: ImageDataFragment;
  thumbnailSize: "small" | "medium" | "large";
  onDelete: () => void;
  hasError?: boolean;
  adaptiveContainerSize?: Property.Width<string | number>;
};

export const AttachmentsFormImage = ({
  isUploading,
  progress,
  image,
  thumbnailSize,
  onDelete,
  hasError,
  adaptiveContainerSize,
}: AttachmentsFormImageProps) => {
  const { classes, cx } = useStyles({ adaptiveContainerSize });
  const [imageDataUrl, setImageDataUrl] = useState<string>();

  useEffect(() => {
    setImageDataUrl(image.file.url);
  }, []);

  const confirmDialogState = useConfirmDialog();
  const { t } = useTranslation();

  const confirmDelete = () => {
    confirmDialogState.open({
      title: t("Generic.PleaseConfirm"),
      message: t("Timeline.DeleteImageConfirmation"),
      onConfirm: onDelete,
    });
  };

  const isPDF = imageDataUrl?.includes(".pdf");

  return (
    <>
      <div
        className={cx(classes.wrapper, classes[thumbnailSize], {
          [classes.adaptiveSize]: !!adaptiveContainerSize,
        })}>
        <div className={classes.closeIcon} onClick={confirmDelete}>
          <CloseIcon />
        </div>
        <div className={cx(classes.content, { [classes.pdfWrapper]: isPDF })}>
          {hasError ? (
            <Typography>{t("Generic.FailedToUploadImage")}</Typography>
          ) : imageDataUrl?.includes(".pdf") ? (
            <Avatar variant={"square"} src={imageDataUrl} className={classes.uploadPDF}>
              <PdfPreview file={imageDataUrl as string} height="300px" />
            </Avatar>
          ) : (
            <Avatar variant={"square"} src={imageDataUrl} className={classes.uploadImage} />
          )}
          {isUploading && (
            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{ root: classes.progressRoot }}
            />
          )}
        </div>
      </div>
      <ConfirmDialog dialogState={confirmDialogState} />
    </>
  );
};

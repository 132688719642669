import React from "react";

import { Paper, Skeleton, Theme, useMediaQuery } from "@mui/material";

import useStyles from "./UserListDialogSkeleton.styles";

type UserListDialogSkeletonProps = {
  inPeopleGrid?: boolean;
};

export const UserListDialogSkeleton = ({ inPeopleGrid }: UserListDialogSkeletonProps) => {
  const { classes, cx } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const textSizeMultiplcator = isMobile ? 50 : 150;
  const avatarSize = 32;

  return (
    <>
      <Paper className={cx(classes.paper, { [classes.personCardGrid]: inPeopleGrid })}>
        <div className={classes.rowContainer}>
          <div className="left">
            <Skeleton variant="circular" width={avatarSize} height={avatarSize}></Skeleton>
            <Skeleton
              variant="text"
              width={Math.floor(Math.random() * textSizeMultiplcator) + 60}
              height={30}></Skeleton>
          </div>
          <div className="right">
            <Skeleton variant="text" width="75%" height={35}></Skeleton>
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className="left">
            <Skeleton variant="circular" width={avatarSize} height={avatarSize}></Skeleton>
            <Skeleton
              variant="text"
              width={Math.floor(Math.random() * textSizeMultiplcator) + 60}
              height={30}></Skeleton>
          </div>
          <div className="right">
            <Skeleton variant="text" width="75%" height={35}></Skeleton>
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className="left">
            <Skeleton variant="circular" width={avatarSize} height={avatarSize}></Skeleton>
            <Skeleton
              variant="text"
              width={Math.floor(Math.random() * textSizeMultiplcator) + 60}
              height={30}></Skeleton>
          </div>
          <div className="right">
            <Skeleton variant="text" width="75%" height={35}></Skeleton>
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className="left">
            <Skeleton variant="circular" width={avatarSize} height={avatarSize}></Skeleton>
            <Skeleton
              variant="text"
              width={Math.floor(Math.random() * textSizeMultiplcator) + 60}
              height={30}></Skeleton>
          </div>
          <div className="right">
            <Skeleton variant="text" width="75%" height={35}></Skeleton>
          </div>
        </div>
      </Paper>
    </>
  );
};

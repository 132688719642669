import React from "react";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import useStyles from "./TransitionedEntity.styles";

type TransitionedEntityProps = {
  children: string | number;
  className?: string | undefined;
  classes?: Record<"root" | "entity", string>;
};

export const TransitionedEntity = ({ children, className, classes }: TransitionedEntityProps) => {
  const {
    classes: { entity, wrapper },
    cx,
  } = useStyles();

  return (
    <TransitionGroup className={cx(wrapper, className, classes?.root)}>
      <CSSTransition key={children} timeout={220} classNames={entity}>
        <div className={cx(classes?.entity, entity)}>{children}</div>
      </CSSTransition>
    </TransitionGroup>
  );
};

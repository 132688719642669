import React, { SVGProps } from "react";

const FileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="m18.773 7.797-3.886-4.5A.865.865 0 0 0 14.24 3h-7a2.182 2.182 0 0 0-1.573.648c-.42.422-.66.998-.667 1.602v13.5c.007.604.247 1.18.667 1.602.42.422.986.655 1.573.648h9.52a2.182 2.182 0 0 0 1.573-.648c.42-.422.66-.998.667-1.602V8.4a.914.914 0 0 0-.227-.603zM14.37 8.4a.706.706 0 0 1-.621-.765V4.8l3.273 3.6H14.37z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default FileIcon;

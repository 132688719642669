import React from "react";

export const PlayButton = ({ backgroundColor = "#f6f6f6", ...props }: any) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill={backgroundColor} cx="32" cy="32" r="32" />
        <path d="M18 17h31v31H18z" />
        <path
          d="M29.125 42.833a3.84 3.84 0 0 1-1.567-.344 3.03 3.03 0 0 1-1.808-2.738v-14.5a3.03 3.03 0 0 1 1.808-2.738 3.616 3.616 0 0 1 3.806.448l8.782 7.25a2.928 2.928 0 0 1 0 4.58l-8.782 7.25a3.547 3.547 0 0 1-2.239.792z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

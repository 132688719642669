import dynamic from "next/dynamic";
import React, { useRef, forwardRef, ForwardedRef, useImperativeHandle } from "react";

import { Theme, useMediaQuery } from "@mui/material";

import { DeltaStatic, StringMap } from "quill";
import type ReactQuill from "react-quill";

import { useStyles } from "./TextEditor.styles";
import { TextEditorProps } from "./TextEditorCore";

const TextEditorCore = dynamic(() => import("./TextEditorCore"), { ssr: false });

export type TextEditorRefProps = {
  focus: () => void;
  blur: () => void;
  clear: () => void;
  setValue: (value: string) => void;
  setHtmlValue: (value: string) => void;
  getDelta: () => StringMap | DeltaStatic | undefined;
};

const TextEditor = (
  { className, onClick, ...props }: TextEditorProps,
  ref: ForwardedRef<TextEditorRefProps>,
) => {
  const { classes, cx } = useStyles();
  const textEditor = useRef<ReactQuill>(null);
  const textEditorWrapper = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  useImperativeHandle(ref, () => ({
    focus: () => {
      textEditor.current?.focus();

      const quill = textEditor.current?.getEditor();
      quill?.setSelection(quill.getLength(), 0);
      if (!isMobile && textEditorWrapper.current) {
        const { top, bottom } = textEditorWrapper.current.getBoundingClientRect();
        const isInViewport =
          top >= 0 && bottom <= (window.innerHeight || document.documentElement.clientHeight);
        if (!isInViewport) {
          textEditorWrapper?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    },
    blur: () => {
      textEditor.current?.blur();
    },
    clear: () => {
      const quill = textEditor.current?.getEditor();
      if (quill) {
        quill.deleteText(0, quill.getLength());
      }
    },
    getDelta: () => {
      const quill = textEditor.current?.getEditor();
      if (quill) {
        return quill.getContents();
      }
    },
    setValue: (value: string) => {
      const quill = textEditor.current?.getEditor();
      quill?.setText(value);
    },
    setHtmlValue: (value: string) => {
      const quill = textEditor.current?.getEditor();
      quill?.clipboard.dangerouslyPasteHTML(value);
    },
  }));

  return (
    <div
      data-cy="TextEditor"
      ref={textEditorWrapper}
      className={cx(classes.wrapper, className, {
        [classes.blogArticleEditor]: props.isBlogArticleEditor,
        [classes.frHeadings]:
          typeof window !== "undefined" && window.location.pathname.startsWith("/fr/"),
      })}
      onClick={onClick}>
      <span className="notranslate">
        <TextEditorCore {...props} textEditorRef={textEditor} />
      </span>
    </div>
  );
};

export default forwardRef<TextEditorRefProps, TextEditorProps>(TextEditor);

import React, { HTMLProps, useEffect, useRef, useState } from "react";

import { Dialog, Theme, useMediaQuery, useTheme } from "@mui/material";

import Hls from "hls.js";
import { useTranslation } from "react-i18next";

import CloseIcon from "@/common/icons/CloseIcon";
import { PlayButton } from "@/common/icons/PlayButton";

import { useStyles } from "./VideoPlayer.styles";

/**
 * Component based on mux documentation.
 *
 * See https://docs.mux.com/guides/video/play-your-videos#3-use-the-hls-url-in-a-player
 */
export const VideoPlayer = ({
  src,
  noPoster,
  ...props
}: { noPoster?: boolean } & HTMLProps<HTMLVideoElement & HTMLDivElement>) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [active, setActive] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    let hls: Hls;
    if (src && videoRef.current) {
      const video = videoRef.current;

      if (src.startsWith("blob:") || video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
        videoRef.current.play();
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef, active]);

  const handlePlay = () => {
    setActive(true);
  };

  const handleClose = () => {
    setActive(false);
  };

  return active ? (
    <>
      {isMobile ? (
        <Dialog open={active} fullScreen={active} classes={{ paper: classes.playerDialog }}>
          <a className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </a>
          <video controls autoPlay ref={videoRef} className={classes.videoElement} {...props} />
        </Dialog>
      ) : (
        <video controls autoPlay ref={videoRef} className={classes.videoElement} {...props} />
      )}
    </>
  ) : (
    <div {...props} className={cx(props.className, classes.preview)}>
      {noPoster ? (
        <div className={classes.emptyPoster}></div>
      ) : props.poster ? (
        <img src={props.poster} alt={t("Common.Thumbnail")} className={classes.poster} />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1024"
          height="576"
          className={classes.poster}>
          <g>
            <rect height="576" width="1024" y="0" x="0" fill="#ccc" />
          </g>
        </svg>
      )}
      <button onClick={handlePlay} className={classes.playButton}>
        <PlayButton backgroundColor={theme.mode.background.lighter} />
      </button>
    </div>
  );
};

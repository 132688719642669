import React, { ComponentType } from "react";

import { Link, Typography, Radio } from "@mui/material";

import UserAvatar from "@/common/components/UserAvatar";

import VerifiedIcon from "@/common/icons/VerifiedIcon";

import { UserListEntity } from "@/common/models/UserListEntity";

import useStyles from "./UserListDialogUser.styles";

type UserListDialogUserProps = {
  user: UserListEntity;
  selected?: boolean;
  UserAction?: ComponentType<{ [key: string]: unknown } & { user: UserListEntity }>;
  userActionProps?: { [key: string]: unknown };
  onUserSelect?: (id: string) => void;
};

export const UserListDialogUser = ({
  user,
  selected,
  UserAction,
  userActionProps,
  onUserSelect,
}: UserListDialogUserProps) => {
  const { classes } = useStyles();

  return (
    <section
      key={user.id}
      className={classes.personOption}
      onClick={() => onUserSelect && onUserSelect(user.id)}>
      <Link href={onUserSelect ? undefined : user.url}>
        <UserAvatar className={classes.personOptionAvatar} user={user} />
      </Link>
      <Link href={onUserSelect ? undefined : user.url} underline="none">
        <Typography variant="h5" className={classes.personOptionName} noWrap>
          {user.displayName}
          {user.verified && <VerifiedIcon />}
        </Typography>
      </Link>
      {UserAction ? (
        <UserAction user={user} {...userActionProps} />
      ) : (
        onUserSelect && (
          <Radio
            classes={{ root: classes.personOptionRadio }}
            checked={selected}
            value={user.id}
            name={user.slug}
            color="primary"
          />
        )
      )}
    </section>
  );
};

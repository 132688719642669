import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ width: string; height: string }>()((_theme: Theme, props) => ({
  previewContainer: {
    left: 0,
    width: props.width,
    height: props.height,
    position: "relative",
  },
  previewIframe: {
    top: 0,
    left: 0,
    width: props.width,
    height: props.height,
    position: "absolute",
    border: 0,
  },
}));

export default useStyles;

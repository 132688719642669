import React, { HTMLAttributes } from "react";

function ChevronDown(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M7.21 9.044c-.563.12-1.003.466-1.154.911-.151.445.01.92.423 1.245l4.344 3.426c.31.242.726.376 1.159.374a1.881 1.881 0 0 0 1.112-.36l4.345-3.307c.44-.284.654-.773.523-1.244-.13-.47-.585-.844-1.175-.967l-.162-.027c-.541-.067-1.095.085-1.466.41l-3.173 2.41L8.79 9.376c-.413-.325-1.014-.452-1.578-.333z"
        />
      </g>
    </svg>
  );
}

export default ChevronDown;

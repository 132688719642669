import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { Property } from "csstype";

const useStyles = makeStyles<
  { adaptiveContainerSize?: Property.Width<string | number> },
  "large" | "closeIcon" | "content" | "uploadImage" | "medium" | "small"
>()((theme: Theme, { adaptiveContainerSize }, classes) => ({
  wrapper: {
    position: "relative",
    [`&.${classes.large}`]: {
      [`& .${classes.closeIcon}`]: {
        width: 20,
        height: 20,
        "& > *": {
          width: 16,
          height: 16,
        },
        [theme.breakpoints.down("sm")]: {
          width: 16,
          height: 16,
          "& > *": {
            width: 12,
            height: 12,
          },
        },
      },
      [`& .${classes.content}`]: {
        maskImage: "radial-gradient(circle 32px at top right, transparent 50%, black 50%)",
        [theme.breakpoints.down("sm")]: {
          maskImage: "radial-gradient(circle 24px at top right, transparent 50%, black 50%)",
        },
      },
      [`& .${classes.uploadImage}`]: {
        width: 132,
        height: 132,
        [theme.breakpoints.down("sm")]: {
          width: 104,
          height: 104,
        },
      },
    },
    [`&.${classes.medium}`]: {
      [`& .${classes.closeIcon}`]: {
        width: 16,
        height: 16,
        "& > *": {
          width: 12,
          height: 12,
        },
      },
      [`& .${classes.content}`]: {
        maskImage: "radial-gradient(circle 24px at top right, transparent 50%, black 50%)",
        borderRadius: 8,
      },
      [`& .${classes.uploadImage}`]: {
        width: 56,
        height: 56,
      },
    },
    [`&.${classes.small}`]: {
      [`& .${classes.closeIcon}`]: {
        width: 12,
        height: 12,
        "& > *": {
          width: 8,
          height: 8,
        },
      },
      [`& .${classes.content}`]: {
        maskImage: "radial-gradient(circle 16px at top right, transparent 50%, black 50%)",
        borderRadius: 8,
      },
      [`& .${classes.uploadImage}`]: {
        width: 40,
        height: 40,
      },
    },
  },
  content: {
    borderRadius: 16,
    overflow: "hidden",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(50%, -50%)",
    backgroundColor: theme.mode.text.heading,
    color: theme.mode.background.default,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 1,
  },
  uploadImage: {},
  uploadPDF: {
    width: "100%",
    height: "300px",
    borderRadius: 8,
    overflow: "hidden",
  },
  pdfWrapper: {
    width: "calc(100% - 18px);",
  },
  badgeClasses: {
    cursor: "pointer",
    right: 0,
    top: 0,
  },
  progressRoot: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
  },
  small: {},
  medium: {},
  large: {},
  adaptiveSize: {
    flexShrink: 0,
    width: `calc((${adaptiveContainerSize} - 4 * ${theme.spacing(1.5)} - ${theme.spacing(
      3,
    )}) / 4.1)`,
    height: `calc((${adaptiveContainerSize} - 4 * ${theme.spacing(1.5)} - ${theme.spacing(
      3,
    )}) / 4.1)`,
    [theme.breakpoints.down("sm")]: {
      width: `calc((100vw - 4 * ${theme.spacing(1.5)}) / 3.1)`,
      height: `calc((100vw - 4 * ${theme.spacing(1.5)}) / 3.1)`,
    },
    [`&.${classes.large}, &.${classes.medium}, &.${classes.small}`]: {
      [`& .${classes.content}, & .${classes.uploadImage}`]: {
        width: "100%",
        height: "100%",
      },
    },
  },
}));

export default useStyles;

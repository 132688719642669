import dynamic from "next/dynamic";
import React from "react";

import { LinearProgress, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import type { ConfirmDialogProps } from "@/common/components/ConfirmDialog/ConfirmDialog";

import CloseIcon from "@/common/icons/CloseIcon";
import VideoIcon from "@/common/icons/VideoIcon";

import { useConfirmDialog } from "@/common/hooks/use-confirm-dialog";

import { VideoDataFragment } from "@/graphql/types";

import useStyles from "./AttachmentsFormVideo.styles";

const ConfirmDialog = dynamic<ConfirmDialogProps>(
  () =>
    import("@/common/components/ConfirmDialog/ConfirmDialog").then(
      (module) => module.ConfirmDialog,
    ),
  {
    ssr: false,
  },
);

type AttachmentsFormVideoProps = {
  isUploading: boolean;
  progress: number;
  video: VideoDataFragment;
  thumbnailSize: "small" | "medium" | "large";
  onDelete: () => void;
  hasError?: boolean;
};

export const AttachmentsFormVideo = ({
  video,
  onDelete,
  hasError,
  isUploading,
  progress,
  thumbnailSize,
}: AttachmentsFormVideoProps) => {
  const { classes, cx } = useStyles();
  const confirmDialogState = useConfirmDialog();
  const { t } = useTranslation();

  const confirmDelete = () => {
    confirmDialogState.open({
      title: t("Generic.PleaseConfirm"),
      message: t("Timeline.DeleteVideoConfirmation"),
      onConfirm: onDelete,
    });
  };

  return (
    <>
      <div className={cx(classes.wrapper, classes[thumbnailSize])}>
        <div className={classes.closeIcon} onClick={confirmDelete}>
          <CloseIcon />
        </div>
        <div className={classes.content}>
          {hasError ? (
            <Typography>{t("Generic.FailedToUploadVideo")}</Typography>
          ) : (
            video.url && <video className={classes.videoElement} controls={false} src={video.url} />
          )}

          <div className={classes.iconContainer}>
            <VideoIcon className={classes.icon} />
          </div>

          {isUploading && (
            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{ root: classes.progressRoot }}
            />
          )}
        </div>
      </div>
      <ConfirmDialog dialogState={confirmDialogState} />
    </>
  );
};

import { ImageDataFragment, FileDataFragment } from "@/graphql/types";

import { uploadVideoToMux } from "./MuxVideoUploader";
import { PhotoUtils } from "./PhotoUtils";

type GetImageUrlsProps = {
  count: number;
  edges: Array<{ node: ImageDataFragment }>;
};

const getImageUrls = (images: GetImageUrlsProps) => {
  return images.edges.map(
    ({
      node: {
        file: { url },
      },
    }) => url,
  );
};

type GetFormattedFilesProps = {
  count: number;
  edges: Array<{ node: FileDataFragment }>;
};

const getFormattedFiles = (files: GetFormattedFilesProps) =>
  files.edges.map(
    ({
      node: {
        id,
        file: { url, name },
      },
    }) => ({
      id,
      url,
      name,
    }),
  );

export { PhotoUtils, getImageUrls, uploadVideoToMux, getFormattedFiles };

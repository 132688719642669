import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<
  void,
  "large" | "closeIcon" | "content" | "iconContainer" | "medium" | "small" | "icon"
>()((theme: Theme, _params, classes) => ({
  wrapper: {
    position: "relative",
    [`&.${classes.large}`]: {
      [`& .${classes.closeIcon}`]: {
        width: 20,
        height: 20,
        "& > *": {
          width: 16,
          height: 16,
        },
        [theme.breakpoints.down("sm")]: {
          width: 16,
          height: 16,
          "& > *": {
            width: 12,
            height: 12,
          },
        },
      },
      [`& .${classes.content}`]: {
        maskImage: "radial-gradient(circle 32px at top right, transparent 50%, black 50%)",
        width: 132,
        height: 132,
        [theme.breakpoints.down("sm")]: {
          maskImage: "radial-gradient(circle 24px at top right, transparent 50%, black 50%)",
          width: 104,
          height: 104,
        },
      },
      [`& .${classes.iconContainer}`]: {
        width: 40,
        height: 40,
      },
    },
    [`&.${classes.medium}`]: {
      [`& .${classes.closeIcon}`]: {
        width: 16,
        height: 16,
        "& > *": {
          width: 12,
          height: 12,
        },
      },
      [`& .${classes.content}`]: {
        maskImage: "radial-gradient(circle 24px at top right, transparent 50%, black 50%)",
        height: 56,
        width: 56,
        borderRadius: 8,
      },
      [`& .${classes.iconContainer}`]: {
        width: 32,
        height: 32,
      },
    },
    [`&.${classes.small}`]: {
      [`& .${classes.closeIcon}`]: {
        width: 12,
        height: 12,
        "& > *": {
          width: 8,
          height: 8,
        },
      },
      [`& .${classes.content}`]: {
        maskImage: "radial-gradient(circle 16px at top right, transparent 50%, black 50%)",
        height: 40,
        width: 40,
        borderRadius: 8,
      },
      [`& .${classes.iconContainer}`]: {
        width: 20,
        height: 20,
        [`& .${classes.icon}`]: {
          width: 12,
          height: 12,
        },
      },
    },
  },
  content: {
    borderRadius: 16,
    backgroundColor: theme.mode.background.default,
    overflow: "hidden",
  },
  videoElement: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  iconContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    fill: theme.palette.primary.contrastText,
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(50%, -50%)",
    backgroundColor: theme.mode.text.heading,
    color: theme.mode.background.default,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 1,
  },
  badgeClasses: {
    cursor: "pointer",
    right: 0,
    top: 0,
  },
  progressRoot: {
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  small: {},
  medium: {},
  large: {},
}));

export default useStyles;

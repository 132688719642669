import { CSSProperties } from "@mui/styled-engine";
import { makeStyles } from "tss-react/mui";

export default makeStyles()(() => {
  const entityTransition = {
    transitionProperty: "opacity, transform",
    transitionDuration: ".22s",
    transitionTimingFunction: "ease-in-out",
  } as CSSProperties;
  return {
    wrapper: {
      display: "inline-block",
    },
    entity: {
      "&-enter": {
        opacity: 0,
        transform: "translateY(-50%)",
      },
      "&-enter-active": {
        ...entityTransition,
        opacity: 1,
        transform: "translateY(0)",
        position: "absolute",
      },
      "&-exit": {
        opacity: 1,
        transform: "translateY(0)",
      },
      "&-exit-active": {
        ...entityTransition,
        opacity: 0,
        transform: "translateY(50%)",
      },
    },
  };
});

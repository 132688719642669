import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  userListDrawer: {
    zIndex: 1241,
  },
  userListDrawerPaper: {
    overflow: "visible",
    maxHeight: "60vh",
  },
  userListDrawerHeader: {
    position: "absolute",
    top: -65,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    visibility: "visible",
    right: 0,
    left: 0,
    backgroundColor: theme.mode.background.light,
  },
  puller: {
    width: 30,
    height: 6,
    backgroundColor: theme.mode.icon.light,
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  },
  userListTitle: {
    padding: theme.spacing(3, 0, 2, 0),
    color: theme.mode.text.heading,
    fontWeight: "bold",
    margin: 0,
    fontSize: "1.065rem",
    lineHeight: "1.5",
    textAlign: "center",
  },
  userListDrawerContent: {
    padding: theme.spacing(0, 2, 2, 2),
    height: "100%",
    overflow: "auto",
  },
}));

export default useStyles;

export interface PhotoTile {
  url: string;
  cols: number;
  rows: number;
  hiddenTile?: boolean;
}

type Tile = [number, number, boolean?]; // [rows, columns, hiddenTile]
type Layout = Tile[];

const layoutsXs: { [key: number]: Layout } = {
  // [[rows, columns]]
  0: [],
  1: [[2, 4]],
  2: [
    [2, 4],
    [2, 4],
  ],
  3: [
    [2, 2],
    [2, 2],
    [2, 4],
  ],
  4: [
    [2, 2],
    [2, 2],
    [2, 2],
    [2, 2],
  ],
  5: [
    [2, 2],
    [2, 2],
    [2, 2],
    [2, 2, true],
  ],
};

const layoutsMd: { [key: number]: Layout } = {
  // [[rows, columns]]
  0: [],
  1: [[3, 4]],
  2: [
    [3, 2],
    [3, 2],
  ],
  3: [
    [2, 2],
    [2, 2],
    [2, 4],
  ],
  4: [
    [2, 2],
    [2, 2],
    [2, 2],
    [2, 2],
  ],
  5: [
    [2, 2],
    [2, 2],
    [2, 2],
    [2, 2, true],
  ],
};

const maxLayout = 5;

export class PhotoUtils {
  static map(urls: string[], breakpoint: "md" | "xs"): PhotoTile[] {
    const layouts = breakpoint === "md" ? layoutsMd : layoutsXs;
    const layoutIndex = Math.min(urls.length, maxLayout);
    const layout = layouts[layoutIndex];

    return urls.slice(0, layout.length).map((url, index) => {
      const [rows, cols, hiddenTile] = layout[index];
      return {
        url: url,
        cols,
        rows,
        hiddenTile,
      };
    });
  }
}

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    "& .ql-editor img.emoji": {
      height: "1em",
      width: "1em",
      maxWidth: "1em",
      margin: "0 .05em 0 .1em",
      verticalAlign: "-0.1em",
    },

    "& .loading-image-container": {
      opacity: 0.3,
      pointerEvents: "none",
    },
  },
  blogArticleEditor: {
    "& .quill": {
      backgroundColor: theme.mode.background.default,
    },
    "& .ql-toolbar": {
      border: "none",
      display: "flex",
      gap: theme.spacing(1.5),
      backgroundColor: theme.mode.background.light,
      borderTopColor: theme.mode.separator.list + " !important",
      borderBottomColor: theme.mode.separator.list + " !important",
    },
    "& .ql-toolbar.ql-snow .ql-formats": {
      marginRight: 0,
    },
    "& .ql-container": {
      border: "none",
      fontSize: "1.0625rem",
      whiteSpace: "pre-line",
      color: theme.mode.text.description,
      padding: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.93rem",
      },
      "& .ql-editor": {
        padding: 0,
      },
    },
  },
  frHeadings: {
    '& .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before':
      {
        content: '"Titre 1"',
      },
    '& .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before':
      {
        content: '"Titre 2"',
      },
  },
}));

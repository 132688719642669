import React, { useState, ComponentType, useEffect } from "react";

import { Box } from "@mui/material";

import InfiniteScroll from "react-infinite-scroll-component";

import Spinner from "@/common/components/Spinner";

import { UserListEntity } from "@/common/models/UserListEntity";

import { UserListDialogSkeleton } from "../../UserListDialogSkeleton/UserListDialogSkeleton";
import { UserListDialogUser } from "../UserListDialogUser/UserListDialogUser";

type UserListDialogUsersProps = {
  users: Array<UserListEntity>;
  loading?: boolean;
  hasMoreUsers?: boolean;
  multiselect?: boolean;
  UserAction?: ComponentType<{ [key: string]: unknown } & { user: UserListEntity }>;
  userActionProps?: { [key: string]: unknown };
  fetchMoreUsers?: () => void;
  onScroll: (event: MouseEvent) => void;
  onChange?: (ids: string[]) => void;
};

export const UserListDialogUsers = ({
  users,
  loading,
  hasMoreUsers = false,
  multiselect = true,
  UserAction,
  userActionProps = {},
  fetchMoreUsers,
  onScroll,
  onChange,
}: UserListDialogUsersProps) => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const handleUserSelect = (id: string) => {
    if (multiselect) {
      setSelectedUserIds((ids) =>
        ids.includes(id) ? ids.filter((selectedId) => selectedId !== id) : [...ids, id],
      );
    } else {
      setSelectedUserIds([id]);
    }
  };

  useEffect(() => {
    onChange && onChange(selectedUserIds);
  }, [selectedUserIds.length]);

  return loading || users.length === 0 ? (
    <div>
      <UserListDialogSkeleton />
    </div>
  ) : (
    <InfiniteScroll
      dataLength={users.length}
      next={() => fetchMoreUsers && fetchMoreUsers()}
      hasMore={hasMoreUsers}
      loader={
        <Box mt={1} display="flex" justifyContent="center">
          <Spinner />
        </Box>
      }
      endMessage={null}
      scrollableTarget="scrollableDiv"
      onScroll={onScroll}>
      {users.map((user) => (
        <UserListDialogUser
          key={user.id}
          user={user}
          UserAction={UserAction}
          userActionProps={userActionProps}
          onUserSelect={onChange ? handleUserSelect : undefined}
          selected={selectedUserIds?.includes(user.id)}
        />
      ))}
    </InfiniteScroll>
  );
};

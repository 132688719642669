import React from "react";

import { ImageList, ImageListItem, Theme, useMediaQuery } from "@mui/material";

import { PdfPreview } from "@/attachments/components/pdf-preview/PdfPreview";

import { PhotoUtils } from "@/attachments/services";

import useStyles from "./ImageGrid.styles";

type ImageGridProps = {
  onClick: (index: any) => void;
  imageUrls: string[];
  rowHeight?: number;
  className?: string;
};

export const ImageGrid = (props: ImageGridProps) => {
  const { classes, cx } = useStyles();
  const imageUrls = props.imageUrls || [];
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const tiles = PhotoUtils.map(imageUrls, mdUp ? "md" : "xs");
  const hiddenCount = imageUrls.length - tiles.length + 1;
  const rowHeight = tiles.length === 1 ? "auto" : props.rowHeight ? props.rowHeight / 2 : 120;

  return (
    <ImageList
      className={cx(classes.mainContainer, props.className)}
      rowHeight={rowHeight}
      cols={4}>
      {tiles.map((tile, index) => (
        <ImageListItem
          cols={tile.cols}
          rows={tile.rows}
          key={tile.url}
          classes={{ root: "imageOverride" }}
          onClick={() => props.onClick(index)}>
          {tile.url.includes(".pdf") ? (
            <PdfPreview file={tile.url} height="100%" />
          ) : (
            <img
              src={tile.url}
              className={cx({
                [classes.hiddenImage]: tile.hiddenTile,
                [classes.singleImage]: tiles.length === 1,
              })}
              alt="Image"
            />
          )}
          {tile.hiddenTile && <div className={classes.hiddenTile}>+{hiddenCount}</div>}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

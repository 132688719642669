import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  dialog: {
    borderRadius: 24,
    width: "612px",
    maxWidth: "612px",
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
      overflow: "hidden",
    },
  },
  headerRoot: {
    padding: "32px 24px",

    boxShadow: "none",
    transition: "box-shadow 0.22s ease-in-out",
    borderBottom: "2px solid #e4e4e4",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 2, 2, 2),
    },
  },
  withShadow: {
    boxShadow: theme.mode.shadow.dialogHeader,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
  },
  action: {
    fontSize: "1.0625rem",
    fontWeight: "bold",
    color: "#a9a9b2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  headerTitle: {
    fontWeight: "800",
    fontSize: "1.0625rem",
    margin: 0,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    color: theme.mode.text.heading,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.065rem",
    },
  },
  closeButton: {
    position: "absolute",
    top: 13,
    right: 24,
    height: "auto",
  },
  content: {
    padding: theme.spacing(0, 0, 2, 0),
    paddingTop: "24px !important",
    maxHeight: "525px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(3),
  },
  searchIcon: {
    position: "absolute",
    marginLeft: theme.spacing(2),
    width: theme.spacing(3),
    height: theme.spacing(3),
    zIndex: 1,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
  },
  searchInput: {
    fontSize: "0.93rem",
    paddingLeft: theme.spacing(3),
    borderRadius: 24,
  },
  personOption: {
    display: "grid",
    gridTemplateColumns: `${theme.spacing(5)} 1fr auto`,
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 5),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: `${theme.spacing(4)} 1fr auto`,
      gap: theme.spacing(1),
      padding: theme.spacing(1, 2),
    },
  },
  personOptionAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  personOptionName: {
    fontSize: "1.313rem",
    color: theme.mode.text.heading,
    fontWeight: "bold",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.93rem",
    },
  },
}));

import { useState } from "react";

import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

import { useAuthenticationStore } from "@/authentication/hooks";

import { UploadsStatus, AttachmentTypes } from "@/attachments/models";
import { uploadVideoToMux } from "@/attachments/services";
import { appSettings } from "@/common/constants";
import {
  ImageDataFragment,
  FileDataFragment,
  VideoDataFragment,
  useUploadFileMutation,
  useUploadImageMutation,
  useCreateVideoUploadMutation,
  useUploadBlogImageMutation,
} from "@/graphql/types";

export const useUploadsStore = (props: {
  images?: ImageDataFragment[];
  files?: FileDataFragment[];
  video?: VideoDataFragment;
}) => {
  const { t } = useTranslation();
  const { session } = useAuthenticationStore();
  const [uploadImage, { loading: uploadImageLoading }] = useUploadImageMutation();
  const [uploadBlogImage] = useUploadBlogImageMutation();
  const [uploadVideo, { loading: uploadVideoLoading }] = useCreateVideoUploadMutation();
  const [uploadFile, { loading: uploadFileLoading }] = useUploadFileMutation();
  const [images, setImages] = useState<ImageDataFragment[]>(props.images || []);
  const [files, setFiles] = useState<FileDataFragment[]>(props.files || []);
  const [video, setVideo] = useState<VideoDataFragment | undefined>(props.video);
  const [uploadsStatus, setUploadsStatus] = useState<UploadsStatus>({});
  const [muxLoading, setMuxLoading] = useState(false);

  const readImageDataUrl = (image: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result;
        if (dataUrl) {
          resolve(dataUrl.toString());
        } else {
          reject();
        }
      };
      reader.readAsDataURL(image);
    });
  };

  const handleUploadBlogImages = async (files: File[]) => {
    if (files.length === 0) return;
    const file = files[0];
    try {
      const { data } = await uploadBlogImage({
        variables: {
          file: file,
        },
      });

      if (data?.uploadBlogImage?.result?.file.url) {
        return data?.uploadBlogImage?.result?.file.url;
      } else {
        alert(
          `${t("Generic.ErrorMessages.upload")} - ${file.name} - ${t(
            "Generic.ErrorMessages.uploadInvalidExtension",
          )}`,
        );
      }
    } catch {
      alert(`${t("Generic.ErrorMessages.upload")} - ${file.name}`);
    }
    return "";
  };

  const handleUploadImages = (data: File[]) => {
    if (data.length === 0) return;
    return data.slice(0).reduce(async (promise, file, index, array) => {
      await promise;

      if (index === appSettings.uploadPicture.maxNoOfFiles) {
        array.splice(1);
        return;
      }

      const id = v4();
      const imageDataUrl = await readImageDataUrl(file);
      const optimisticResult = {
        id,
        file: {
          url: imageDataUrl,
        },
        type: "image",
      };
      setUploadsStatus((prev) => ({
        ...prev,
        [id]: {
          uploading: true,
          progress: 0,
        },
      }));
      const timer = setInterval(() => {
        setUploadsStatus((prev) => ({
          ...prev,
          [id]: {
            uploading: true,
            progress: prev[id].progress >= 80 ? 90 : prev[id].progress + Math.random() * 10,
          },
        }));
      }, 250);
      setImages((prev) => [...prev, optimisticResult]);
      try {
        const { data } = await uploadImage({
          variables: { image: file },
          optimisticResponse: {
            uploadImage: {
              __typename: "ImagePayload",
              successful: true,
              messages: [],
              result: {
                ...optimisticResult,
                __typename: "Image",
                file: {
                  ...optimisticResult.file,
                  __typename: "AttachmentFile",
                },
              },
            },
          },
        });
        if (data?.uploadImage?.result) {
          clearInterval(timer);
          setUploadsStatus((prev) => ({
            ...prev,
            [data.uploadImage!.result!.id]: {
              uploading: false,
              progress: 100,
            },
          }));
          setImages((prev) => [...prev.filter((img) => id !== img.id), data.uploadImage!.result!]);
        } else {
          alert(
            `${t("Generic.ErrorMessages.upload")} - ${file.name} - ${t(
              "Generic.ErrorMessages.uploadInvalidExtension",
            )}`,
          );
          setImages((prev) => prev.filter((img) => id !== img.id));
        }
      } catch {
        alert(`${t("Generic.ErrorMessages.upload")} - ${file.name}`);
        setImages((prev) => prev.filter((img) => id !== img.id));
      }
    }, Promise.resolve());
  };

  const handleUploadFiles = (data: File[]) => {
    if (data.length === 0) return;
    const maxSizeLimit = session?.user?.hasIncreasedUploadLimits
      ? appSettings.uploadFile.increasedMaxSize
      : appSettings.uploadFile.defaultMaxSize;

    return data.reduce(async (promise, file, index, array) => {
      await promise;

      if (index === appSettings.uploadFile.maxNoOfFiles) {
        array.splice(1);
        return;
      }

      if (file.size > maxSizeLimit) {
        const errorMessage = session?.user?.hasIncreasedUploadLimits
          ? t("Generic.FileTooLarge3_5GB")
          : t("Generic.FileTooLarge500MB");
        return alert(errorMessage);
      }

      const id = v4();
      const optimisticResult = {
        id,
        file: { url: "", name: file.name },
        type: "file",
      };
      setUploadsStatus((prev) => ({
        ...prev,
        [id]: {
          uploading: true,
          progress: 0,
        },
      }));
      const timer = setInterval(() => {
        setUploadsStatus((prev) => ({
          ...prev,
          [id]: {
            uploading: true,
            progress: prev[id].progress >= 80 ? 90 : prev[id].progress + Math.random() * 10,
          },
        }));
      }, 250);
      setFiles((prev) => [...prev, optimisticResult]);
      try {
        const { data } = await uploadFile({
          variables: { file },
          optimisticResponse: {
            uploadFile: {
              __typename: "FilePayload",
              successful: true,
              messages: [],
              result: {
                ...optimisticResult,
                __typename: "File",
                file: {
                  ...optimisticResult.file,
                  __typename: "AttachmentFile",
                },
              },
            },
          },
          context: {
            Headers: {
              "Content-Disposition": "attachment",
            },
          },
        });
        if (data?.uploadFile?.result) {
          clearInterval(timer);
          setUploadsStatus((prev) => ({
            ...prev,
            [data.uploadFile!.result!.id]: {
              uploading: false,
              progress: 100,
            },
          }));
          setFiles((prev) => [...prev.filter((img) => id !== img.id), data.uploadFile!.result!]);
        } else {
          alert(
            `${t("Generic.ErrorMessages.upload")} - ${file.name} - ${t(
              "Generic.ErrorMessages.uploadInvalidExtension",
            )}`,
          );
          setFiles((prev) => prev.filter((file) => id !== file.id));
        }
      } catch {
        alert(`${t("Generic.ErrorMessages.upload")} - ${file.name}`);
        setFiles((prev) => prev.filter((file) => id !== file.id));
      }
    }, Promise.resolve());
  };

  const handleVideoUpload = async (video: File) => {
    const maxSizeLimit = session?.user?.hasIncreasedUploadLimits
      ? appSettings.uploadVideo.increasedMaxSize
      : appSettings.uploadVideo.defaultMaxSize;

    const doesVideoExcedMaximumSize = video.size > maxSizeLimit;

    if (doesVideoExcedMaximumSize) {
      const errorMessage = session?.user?.hasIncreasedUploadLimits
        ? t("Generic.FileTooLarge3_5GB")
        : t("Generic.FileTooLarge500MB");
      return alert(errorMessage);
    }

    const { data } = await uploadVideo();
    if (data?.createVideoUpload?.result) {
      const { attachmentId, uploadUrl } = data.createVideoUpload.result;

      setVideo({
        id: attachmentId,
        url: URL.createObjectURL(video),
      });
      setUploadsStatus({
        ...uploadsStatus,
        [attachmentId]: {
          uploading: true,
          progress: 0,
        },
      });
      setMuxLoading(true);

      return new Promise((resolve, reject) => {
        uploadVideoToMux({
          file: video,
          uploadUrl,
        }).subscribe({
          next(progress) {
            setUploadsStatus({
              ...uploadsStatus,
              [attachmentId]: {
                uploading: true,
                progress,
              },
            });
          },
          error(error) {
            setMuxLoading(false);
            reject(error);
          },
          complete() {
            setMuxLoading(false);
            setUploadsStatus({
              ...uploadsStatus,
              [attachmentId]: {
                uploading: false,
                progress: 100,
              },
            });
            resolve(attachmentId);
          },
        });
      });
    } else {
      return alert(
        `${t("Generic.ErrorMessages.upload")} - ${video.name} - ${t(
          "Generic.ErrorMessages.uploadInvalidExtension",
        )}`,
      );
    }
  };

  return {
    images,
    files,
    video,
    loading: uploadImageLoading || uploadFileLoading || uploadVideoLoading || muxLoading,
    imageIds: images.map(({ id }) => id),
    fileIds: files.map(({ id }) => id),
    videoId: video?.id,
    uploadsStatus,
    uploadImages: handleUploadImages,
    uploadVideo: handleVideoUpload,
    deleteImageAt(id: string) {
      setImages(images.filter((image) => image.id !== id));
    },
    deleteFileAt(id: string) {
      setFiles(files.filter((file) => file.id !== id));
    },
    deleteVideo() {
      setVideo(undefined);
    },
    uploadFiles(data: File[], type?: AttachmentTypes) {
      switch (type) {
        case AttachmentTypes.BlogArticleImage:
          return handleUploadBlogImages(data);
        case AttachmentTypes.Image:
          return handleUploadImages(data);
        case AttachmentTypes.File:
          return handleUploadFiles(data);
        case AttachmentTypes.Video:
          return handleVideoUpload(data[0]);
        default:
          data.reduce(
            (grouped: { images: File[]; files: File[]; video?: File }, file, index) => {
              if (file.type.startsWith("video/")) {
                grouped.video = file;
              } else if (file.type.startsWith("image/")) {
                grouped.images.push(file);
              } else {
                grouped.files.push(file);
              }
              if (data.length - 1 === index) {
                grouped.video && handleVideoUpload(grouped.video);
                handleUploadImages(grouped.images);
                handleUploadFiles(grouped.files);
              }
              return grouped;
            },
            { images: [], files: [] },
          );
      }
    },
    clearUploads() {
      setImages([]);
      setFiles([]);
      setVideo(undefined);
    },
  };
};

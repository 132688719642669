import React, { forwardRef, ForwardedRef } from "react";

import { Switch, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "checked" | "track" | "focusVisible" | "thumb">()(
  (theme: Theme, _params, classes) => {
    return {
      root: {
        width: 48,
        height: 26,
        padding: 0,
        [theme.breakpoints.down("sm")]: {
          width: 51,
          height: 28,
        },
      },
      switchBase: {
        padding: 1,
        height: 24,
        boxSizing: "initial",
        [`&.${classes.checked}`]: {
          transform: "translateX(20px)",
          color: "white",
          [theme.breakpoints.down("sm")]: {
            transform: "translateX(23px)",
          },
          [`& + .${classes.track}`]: {
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
            border: "none",
          },
        },
        [`&.${classes.focusVisible} .${classes.thumb}`]: {
          color: "#fff",
          border: `6px solid ${theme.palette.primary.main}`,
        },
        [theme.breakpoints.down("sm")]: {
          height: 26,
          padding: "1px 0",
        },
      },
      thumb: {
        color: "#fff",
        width: 20,
        height: 20,
        boxShadow: "unset",
        marginLeft: 3,
        [theme.breakpoints.down("sm")]: {
          width: 24,
          height: 24,
          marginLeft: 2,
        },
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid #a9a9b2`,
        backgroundColor: "#a9a9b2",
        opacity: 1,
        transitionProperty: "background-color border",
        transitionDuration: "0.5s",
        [theme.breakpoints.down("sm")]: {
          borderRadius: 28 / 2,
        },
      },
      checked: {},
      focusVisible: {},
    };
  },
);

const CustomSwitch = (props: { [key: string]: unknown }, ref: ForwardedRef<HTMLInputElement>) => {
  const { classes } = useStyles();
  return (
    <Switch
      inputRef={ref}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      disabled={props.disabled as boolean}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      inputProps={{ "aria-label": "primary checkbox" }}
      {...props}
    />
  );
};

export default forwardRef<HTMLInputElement, { [key: string]: unknown }>(CustomSwitch);

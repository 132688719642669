import dynamic from "next/dynamic";
import React from "react";

import { LinearProgress, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import type { ConfirmDialogProps } from "@/common/components/ConfirmDialog/ConfirmDialog";

import CloseIcon from "@/common/icons/CloseIcon";
import FileIcon from "@/common/icons/FileIcon";

import { useConfirmDialog } from "@/common/hooks/use-confirm-dialog";

import { FileDataFragment } from "@/graphql/types";

import useStyles from "./AttachmentsFormFile.styles";

const ConfirmDialog = dynamic<ConfirmDialogProps>(
  () =>
    import("@/common/components/ConfirmDialog/ConfirmDialog").then(
      (module) => module.ConfirmDialog,
    ),
  {
    ssr: false,
  },
);

type AttachmentsFormFileProps = {
  isUploading: boolean;
  progress: number;
  file: FileDataFragment;
  thumbnailSize: "small" | "medium" | "large";
  onDelete: () => void;
  hasError?: boolean;
};

export const AttachmentsFormFile = ({
  isUploading,
  progress,
  file,
  thumbnailSize,
  onDelete,
  hasError,
}: AttachmentsFormFileProps) => {
  const { classes, cx } = useStyles();

  const confirmDialogState = useConfirmDialog();
  const { t } = useTranslation();

  const confirmDelete = () => {
    confirmDialogState.open({
      title: t("Generic.PleaseConfirm"),
      message: t("Timeline.DeleteFileConfirmation"),
      onConfirm: onDelete,
    });
  };

  return (
    <>
      <div className={cx(classes.wrapper, classes[thumbnailSize])}>
        <div className={classes.closeIcon} onClick={confirmDelete}>
          <CloseIcon />
        </div>
        <div className={classes.content}>
          {hasError ? (
            <Typography>{t("Generic.FailedToUploadImage")}</Typography>
          ) : (
            <div className={classes.uploadFile}>
              <FileIcon />
              <span className={classes.uploadFileName}>{file.file.name}</span>
            </div>
          )}
          {isUploading && (
            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{ root: classes.progressRoot }}
            />
          )}
        </div>
      </div>
      <ConfirmDialog dialogState={confirmDialogState} />
    </>
  );
};

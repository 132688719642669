import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<
  void,
  "large" | "closeIcon" | "content" | "uploadFile" | "medium" | "small"
>()((theme: Theme, _params, classes) => ({
  wrapper: {
    width: "100%",
    position: "relative",
    [`&.${classes.large}`]: {
      [`& .${classes.closeIcon}`]: {
        width: 20,
        height: 20,
        "& > *": {
          width: 16,
          height: 16,
        },
        [theme.breakpoints.down("sm")]: {
          width: 16,
          height: 16,
          "& > *": {
            width: 12,
            height: 12,
          },
        },
      },
      [`& .${classes.content}`]: {
        maskImage: "radial-gradient(circle 32px at top right, transparent 50%, black 50%)",
        [theme.breakpoints.down("sm")]: {
          maskImage: "radial-gradient(circle 24px at top right, transparent 50%, black 50%)",
        },
      },
      [`& .${classes.uploadFile}`]: {
        height: 56,
      },
    },
    [`&.${classes.medium}`]: {
      [`& .${classes.closeIcon}`]: {
        width: 16,
        height: 16,
        "& > *": {
          width: 12,
          height: 12,
        },
      },
      [`& .${classes.content}`]: {
        maskImage: "radial-gradient(circle 24px at top right, transparent 50%, black 50%)",
        borderRadius: 8,
      },
      [`& .${classes.uploadFile}`]: {
        height: 56,
      },
    },
    [`&.${classes.small}`]: {
      [`& .${classes.closeIcon}`]: {
        width: 12,
        height: 12,
        "& > *": {
          width: 8,
          height: 8,
        },
      },
      [`& .${classes.content}`]: {
        maskImage: "radial-gradient(circle 16px at top right, transparent 50%, black 50%)",
        borderRadius: 8,
      },
      [`& .${classes.uploadFile}`]: {
        height: 40,
      },
    },
    [`&:hover .${classes.uploadFile}`]: {
      backgroundColor: `${theme.palette.primary.main}1f`,
    },
  },
  content: {
    borderRadius: 16,
    overflow: "hidden",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(50%, -50%)",
    backgroundColor: theme.mode.text.heading,
    color: theme.mode.background.default,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 1,
  },
  uploadFile: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.mode.background.light,
    color: theme.palette.primary.main,
    maxWidth: 144,
    display: "inline-grid",
    gridTemplateColumns: "24px 1fr",
    gap: theme.spacing(1),
    padding: theme.spacing(0.25, 1, 0.25, 0.25),
    transition: "background-color 0.22s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 144,
    },
  },
  uploadFileName: {
    color: theme.mode.text.description,
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "1.25rem",
    fontSize: "1.0625rem",
    fontWeight: 600,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.625rem",
    },
  },
  badgeClasses: {
    cursor: "pointer",
    right: 0,
    top: 0,
  },
  progressRoot: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
  },
  small: {},
  medium: {},
  large: {},
}));

export default useStyles;

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  paper: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(0, 1.25),
    boxSizing: "border-box",
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      padding: "12px 24px",
      boxShadow: "none",
    },
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 24px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    "& .left": {
      display: "flex",
      gap: 15,
      width: "50%",
      "& span": {
        flexShrink: 0,
      },
    },
    "& .right": {
      display: "flex",
      justifyContent: "right",
      width: "20%",
    },
    "&:nth-child(1)": { marginTop: 0 },
    "&:nth-child(2)": { marginTop: 10 },
    "&:nth-child(3)": { marginTop: 5 },
    "&:nth-child(4)": { marginTop: 10 },
  },
  personCardGrid: {
    width: 176,
    [theme.breakpoints.down("md")]: {
      width: 160,
    },
  },
}));
